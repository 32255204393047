import axios from 'axios'

export default {
  userLogin (payload) {
    return axios.post('/login', payload)
  },
  userLogout () {
    return axios.get('/logout')
  },
  refreshToken () {
    return axios.get('/token')
  },
}
