// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from '@/App.vue'
import '@/plugins/axios'
import router from '@/router'
import { store } from '@/store'
import '@/plugins/base'
import '@/plugins/chartist'
import '@/plugins/vee-validate'
import '@/plugins/common'
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  created () {
    store.dispatch('setLocale', store.getters.locale)
    if (store.getters.isTokenSet) {
      store.dispatch('autoLogin')
    }
  },
  render: h => h(App),
}).$mount('#app')
