import axios from 'axios'
import router from '@/router'

export default {
  getSchema (params) {
    const module = router.getRoutes().find(o => o.path === router.currentRoute.path).props.default.module
    return axios.get('Schema/' + module, {
      params,
    })
  },
}
