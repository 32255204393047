export default [
  {
    path: '/',
    component: () => import('@/views/dashboard/Index'),
    meta: {
      requiresAuth: true,
    },
    children: [
      // Dashboard
      {
        name: 'Tableau de bord',
        path: '',
        component: () => import('@/views/dashboard/Dashboard'),
        meta: {
          requiresAuth: true,
        },
      },
      // HelpDesk
      {
        name: 'Assistance',
        path: '/helpdesk',
        component: () => import('@/components/HelpDesk.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'HelpDesk',
        },
      },
      // Comptes
      {
        name: 'Mes sociétés',
        path: '/accounts',
        component: () => import('@/components/Accounts.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'Accounts',
        },
      },
      // Contrats
      {
        name: 'Mes contrats',
        path: '/servicecontracts',
        component: () => import('@/components/ServiceContracts.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'ServiceContracts',
        },
      },
      // Parc Materiel
      {
        name: 'Parc Matériel',
        path: '/parcmateriel',
        component: () => import('@/components/ParcMateriel.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'ParcMateriel',
        },
      },
      // Parc Logiciel
      {
        name: 'Parc Logiciel',
        path: '/parclogiciel',
        component: () => import('@/components/ParcLogiciel.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'ParcLogiciel',
        },
      },
      // Contacts
      {
        name: 'Mes collaborateurs',
        path: '/contacts',
        component: () => import('@/components/Contacts.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'Contacts',
        },
      },
      // Reset Password
      {
        path: '/reset',
        name: 'Modifier mon mot de passe',
        component: () => import('@/components/ResetPassword'),
        meta: {
          requiresAuth: true,
        },
      },
      // Vue généric lecture seule
      {
        name: 'Vue générique (R)',
        path: '/vuegenericreadonly',
        component: () => import('@/components/ModuleGenericReadonly.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'Contacts',
        },
      },
      // Vue généric lecture Ecriture
      {
        name: 'Vue générique (RW)',
        path: '/vuegenericform',
        component: () => import('@/components/ModuleGenericForm.vue'),
        meta: {
          requiresAuth: true,
        },
        props: {
          module: 'Contacts',
        },
      },
      // Pages
      {
        name: 'User Profile',
        path: 'pages/user',
        component: () => import('@/views/dashboard/pages/UserProfile'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'Notifications',
        path: 'components/notifications',
        component: () => import('@/views/dashboard/component/Notifications'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'Icons',
        path: 'components/icons',
        component: () => import('@/views/dashboard/component/Icons'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'Typography',
        path: 'components/typography',
        component: () => import('@/views/dashboard/component/Typography'),
        meta: {
          requiresAuth: true,
        },
      },
      // Tables
      {
        name: 'Regular Tables',
        path: 'tables/regular-tables',
        component: () => import('@/views/dashboard/tables/RegularTables'),
        meta: {
          requiresAuth: true,
        },
      },
      // Maps
      {
        name: 'Google Maps',
        path: 'maps/google-maps',
        component: () => import('@/views/dashboard/maps/GoogleMaps'),
        meta: {
          requiresAuth: true,
        },
      },
      // Upgrade
      // {
      //   name: 'Upgrade',
      //   path: 'upgrade',
      //   component: () => import('@/views/dashboard/Upgrade'),
      //   meta: {
      //     requiresAuth: true,
      //   },
      // },
    ],
  },
]
