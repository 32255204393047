import * as types from '@/store/mutation-types'
import api from '@/services/api/records'
import { buildSuccess, handleError } from '@/utils/utils.js'

const getters = {
  records: (state) => state.records,
  totalRecords: (state) => state.totalRecords,
  attachments: (state) => state.attachments,
  totalAttachments: (state) => state.totalAttachments,
}

const actions = {
  getRecords ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getRecords(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.RECORDS, response.data.records)
            commit(types.TOTAL_RECORDS, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  editRecord ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = { form: payload }

      api
        .editRecord(payload._id, data)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY',
              },
              commit,
              resolve,
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveRecord ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('form', JSON.stringify(payload))
      data.append('attachment1', payload.attachment1)
      data.append('attachment2', payload.attachment2)
      data.append('attachment3', payload.attachment3)
      // const data = { form: payload }

      api
        .saveRecord(data)
        .then((response) => {
          if (response.status === 201) {
            payload._id = response.data.record._id
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY',
              },
              commit,
              resolve,
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  saveComment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const data = new FormData()
      data.append('form', JSON.stringify(payload))
      data.append('attachment1', payload.attachment1)
      data.append('attachment2', payload.attachment2)
      data.append('attachment3', payload.attachment3)
      // const data = { form: payload }

      api
        .saveComment(data)
        .then((response) => {
          if (response.status === 201) {
            buildSuccess(
              {
                msg: 'common.SAVED_SUCCESSFULLY',
              },
              commit,
              resolve,
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  deleteRecord ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .deleteRecord(payload)
        .then((response) => {
          if (response.status === 200) {
            buildSuccess(
              {
                msg: 'common.DELETED_SUCCESSFULLY',
              },
              commit,
              resolve,
            )
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  getAttachments ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .getAttachments(payload)
        .then((response) => {
          if (response.status === 200) {
            commit(types.ATTACHMENTS, response.data.records)
            commit(types.TOTAL_ATTACHMENTS, parseInt(response.data.count))
            resolve()
          }
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
  downloadAttachment ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      api
        .downloadAttachment(payload)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', payload.name)
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          handleError(error, commit, reject)
        })
    })
  },
}

const mutations = {
  [types.RECORDS] (state, records) {
    state.records = records
  },
  [types.TOTAL_RECORDS] (state, value) {
    state.totalRecords = value
  },
  [types.ATTACHMENTS] (state, records) {
    state.attachments = records
  },
  [types.TOTAL_ATTACHMENTS] (state, value) {
    state.totalAttachments = value
  },
}

const state = {
  records: [],
  totalRecords: 0,
  attachments: [],
  totalAttachments: 0,
}

export default {
  state,
  getters,
  actions,
  mutations,
}
