<template>
  <v-col
    class="text-center mb-2"
    cols="12"
    v-html="description"
  />
</template>

<script>
  export default {
    name: 'Description',
    props: {
      description: String,
    },
  }
</script>
