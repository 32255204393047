// app
export const SET_APP_VERSION = 'SET_APP_VERSION'
// locale
export const SET_LOCALE = 'SET_LOCALE'
// error
export const SHOW_ERROR = 'SHOW_ERROR'
export const ERROR = 'ERROR'
// success
export const SHOW_SUCCESS = 'SHOW_SUCCESS'
export const SUCCESS = 'SUCCESS'
// loading
export const SHOW_LOADING = 'SHOW_LOADING'
// auth
export const IS_LOGGED_IN = 'IS_LOGGED_IN'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const SAVE_USER = 'SAVE_USER'
export const LOGOUT = 'LOGOUT'
export const EMAIL_VERIFIED = 'EMAIL_VERIFIED'
export const RESET_EMAIL_SENT = 'RESET_EMAIL_SENT'
export const SHOW_CHANGE_PASSWORD_INPUTS = 'SHOW_CHANGE_PASSWORD_INPUTS'
// profile
export const FILL_PROFILE = 'FILL_PROFILE'
export const ADD_PROFILE_DATA = 'ADD_PROFILE_DATA'
// cities
export const FILL_ALL_CITIES = 'FILL_ALL_CITIES'
// Admin - Cities
export const CITIES = 'CITIES'
export const TOTAL_CITIES = 'TOTAL_CITIES'
// Admin - Users
export const USERS = 'USERS'
export const TOTAL_USERS = 'TOTAL_USERS'

// Admin - All
export const RECORDS = 'RECORDS'
export const TOTAL_RECORDS = 'TOTAL_RECORDS'
export const ATTACHMENTS = 'ATTACHMENTS'
export const TOTAL_ATTACHMENTS = 'TOTAL_ATTACHMENTS'

// UTILS
export const ACCOUNTS = 'ACCOUNTS'
export const TOTAL_ACCOUNTS = 'TOTAL_ACCOUNTS'
export const CONTACTS = 'CONTACTS'
export const TOTAL_CONTACTS = 'TOTAL_CONTACTS'
export const POLES = 'POLES'
export const TOTAL_POLES = 'TOTAL_POLES'
export const POINTAGES = 'POINTAGES'
export const TOTAL_POINTAGES = 'TOTAL_POINTAGES'
export const PARCLOGICIEL = 'PARCLOGICIEL'
export const TOTAL_PARCLOGICIEL = 'TOTAL_PARCLOGICIEL'
export const CONFIGURATION = 'CONFIGURATION'
export const TOTAL_CONFIGURATION = 'TOTAL_CONFIGURATION'

// SCHEMA
export const SCHEMA = 'SCHEMA'
